import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
// import Faq from '../views/Faq.vue'
import Offer from '../views/Offer.vue'
import Tarif from '../views/Tarif.vue'
import Policies from '../views/Policies.vue'
import Success from '@/components/Success'
import Pay from '@/components/Pay'
import Choose from '@/components/Choose'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/result',
    name: 'Result',
    component: Success
  },
  {
    path: '/choose',
    name: 'Choose',
    component: Choose
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay
  },
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: Faq
  // },
  {
    path: '/terms',
    name: 'terms',
    component: Offer
  },
  {
    path: '/policies',
    name: 'policies',
    component: Policies
  },
  {
    path: '/tarifs',
    name: 'tarifs',
    component: Tarif
  },
  {
    path: '/sh',
    name: 'sh',
    component: Home,
    beforeEnter: (to, from, next) => {
      store.commit('SHORT_TEST')
      next()
    }
  },
  {
    path: '/light',
    name: 'light',
    component: Home,
    beforeEnter: (to, from, next) => {
      store.commit('SHORT_LIGHT')
      next()
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (['Home', 'sh', 'light'].indexOf(to.name) !== -1) store.dispatch('urlParams', to.query)
  if ((['Result', 'Pay'].indexOf(to.name) !== -1) && store.getters.result === null) next({ name: 'Home' })
  else { next() }
})

router.afterEach((to, from) => {
  store.commit('CLOSE_MENU')
})

export default router
