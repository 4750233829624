<template>
  <div id="phone" :class="[gender]">
    <div :class="['top', gender]">
      <h1 class="title">Дополнительная скидка специально для Вас!</h1>
      <div class="prices">
        <div class="price price-old">
          <div class="price-top">Старая цена</div>
          <div class="price-bottom">849р</div>
        </div>
        <div class="price price-new">
          <div class="price-sale">Скидка!</div>
          <div class="price-top">Новая цена</div>
          <div class="price-bottom">1р</div>
        </div>
      </div>
    </div>

    <ul>
      <li>Профессиональный анализ питания</li>
      <li>120 + вкусных жиросжигающих рецептов</li>
      <li>80+ профессионально составленных тренировок</li>
      <li>Готовый список продуктов</li>
      <li>Потрясающие советы для здорового образа жизни на каждый день</li>
    </ul>

    <button @click="sendResult" :class="[gender]">{{ config?.common.button }}</button>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Pay',
  created () {
    window.dataLayer.push({ event: 'promotion1Rub' })
  },
  computed: {
    ...mapGetters([
      'config',
      'gender'
    ])
  },
  methods: {
    sendResult: function () {
      this.$store.dispatch('setModal', 'Pay')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

#phone {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  position: relative;

  @media (max-width: 500px) {
    padding: 10px 20px;
  }

  .top {
    width: 100%;
    margin-bottom: 30px;

    h1 {
      margin-bottom: 30px;
    }

    &.male h1 {
      color: $color-male-primary;
    }

    &.female h1 {
      color: $color-female-primary;
    }

    .prices {
      width: 400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: stretch;

      @media (max-width: 400px) {
        width: 100%;
      }

      .price {
        border: 2px solid $line-color-dark;
        border-radius: 10px;
        padding: 10px;
        width: 150px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 2px;
          top: calc(50% - 1px);
          background: $line-color-dark;
        }

        &-top {
          padding-bottom: 3px;
        }

        &-bottom {
          padding-top: 3px;
        }

        &-old {
          margin: 5px;
          color: $line-color-dark;
        }

        &-new {
          border-color: $color-male-primary;
          background: $color-male-tertiary;
          color: white;
          font-size: 1.2em;

          &:before {
            border-color: $color-male-primary !important;
          }
        }

        &-sale {
          position: absolute;
          top: calc(-1 * (1em + 5px));
          left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          background-color: #ffaa3d;
          border-radius: 10px;
          padding: 5px 10px;
        }
      }
    }
  }

  button {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 50px;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    font-size: 1.2em;
    font-weight: lighter;
    color: white;
    cursor: pointer;

    &.male {
      background: $color-male-primary;
    }

    &.female {
      background: $color-female-primary;
    }
  }

  ul {
    margin-left: 1em;
    margin-bottom: 30px;

    li {
      list-style-type: none;
      font-size: 1.3em;
      font-weight: lighter;
      margin-bottom: 10px;
      position: relative;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: .4em;
        left: -1em;
      }
    }
  }

  &.male {
    li:before {
      background-color: $color-male-tertiary;
    }
  }

  &.female {
    li:before {
      background-color: $color-female-tertiary;
    }
  }
}
</style>
