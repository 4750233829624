<template>
  <div class="choise">
    <div class="choise__container">
      <div class="choise__logo">
        <img class="choise__logo-img" :src="require('@/assets/img/logo.png')" alt="Лого" />
      </div>
      <p class="choise__subtitle">План похудения Cheat Meal помог более</p>
      <p class="choise__span">1 млн пользователей</p>
      <p class="choise__subtitle">по всему миру достичь идеального веса за счет воспитания здоровых привычек в питании и
        занятии спортом.</p>
      <p class="choise__subtitle choise__subtitle_margin_large">Мы на 100% уверенны в качестве нашего продукта и хотим,
        чтобы вы лично в этом убедились!</p>
      <p class="choise__subtitle">Мы понимаем, что у всех разные финансовые возможности, именно поэтому предлагаем вам
        самостоятельно выбрать справедливую цену!</p>
      <div class="choise__columns">
        <div class="choise__columns-item">
          <div class="choise__text">
            <p class="choise__text-item">Разработка одного <span class="choise__text-span">5-дневного персонального
                плана</span> обходится нам в 2485 руб, но мы предлагаем вам самостоятельно выбрать справедливую цену!
            </p>
          </div>
          <ul class="choise__list">
            <li class="choise__list-item">
              <button class="choise__button" @click="route(1)">1 руб.</button>
            </li>
            <li class="choise__list-item">
              <button class="choise__button" @click="route(99)">99 руб.</button>
            </li>
            <li class="choise__list-item">
              <button class="choise__button" @click="route(490)">490 руб.</button>
            </li>
            <li class="choise__list-item">
              <button class="choise__button" @click="route(890)">890 руб.</button>
            </li>
          </ul>
        </div>
        <div class="choise__columns-item">
          <img class="choise__columns-image" :src="require('@/assets/img/success/choise.png')" alt="Фото результата" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { mapActions } from 'vuex'
export default {
  name: 'choose',
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setPrice'
    ]),
    route: function (price) {
      this.setPrice(price)
      router.push({ name: 'Result' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.choise {
  width: 100%;
  min-height: 100vh;
  background-color: #fff8f4;

  &__container {
    width: 1340px;
    margin: 0 auto;

    @media (max-width: 1404px) {
      width: calc(100% - 64px);
      max-width: 602px;
    }

    @media (max-width: 586px) {
      width: calc(100% - 16px);
    }
  }

  &__logo {
    margin: 0 0 32px 0;

    &-img {
      min-width: 100px;

      @media (max-width: 924px) {
        width: 100px;
      }
    }
  }

  &__subtitle {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    line-height: 1.5;
    max-width: 910px;

    &_margin {
      &_large {
        margin: 40px 0;

        @media (max-width: 924px) {
          margin: 20px 0;
        }
      }
    }

    @media (max-width: 924px) {
      font-size: 13px;
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1280px) {
      flex-direction: column;
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 0 16px 0 0;

      @media (max-width: 1280px) {
        width: 100%;
      }

      &:first-child {
        max-width: 575px;

        @media (max-width: 1280px) {
          max-width: 100%;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    &-image {
      width: 100%;
      max-width: 614px;
      align-self: flex-end;

      @media (max-width: 1280px) {
        align-self: center;
      }
    }
  }

  &__span {
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 1px;
    color: #42b983;

    @media (max-width: 924px) {
      font-size: 26px;
      line-height: 32px;
      word-wrap: break-word;
    }
  }

  &__text {
    margin: 80px 0;
    padding: 10px;
    background-color: #13b123;
    max-width: 575px;
    width: 100%;
    align-self: flex-start;

    @media (max-width: 1280px) {
      align-self: center;
    }

    @media (max-width: 924px) {
      margin: 15px 0 32px 0;
    }

    &-item {
      font-size: 16px;
      color: #fff;
      line-height: 1.5;

      @media (max-width: 924px) {
        font-size: 14px;
      }
    }

    &-span {
      font-weight: 700;
    }
  }

  &__list {
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0;
    padding: 0;
    gap: 16px;
    list-style-type: none;

    @media (max-width: 360px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      width: 100%;
    }
  }

  &__button {
    width: 150px;
    height: 53px;
    background: #42b983;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border-radius: 7px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;

    @media (max-width: 360px) {
      width: 100%;
    }
  }
}
</style>
